import { createAction } from "redux-actions"

export const FETCH_TOKEN_SUMMARY_DATA_REQUEST =
  "FETCH_TOKEN_SUMMARY_DATA_REQUEST"
export const fetchTokenSummaryDataRequest = createAction(
  FETCH_TOKEN_SUMMARY_DATA_REQUEST
)

export const FETCH_TOKEN_SUMMARY_DATA_SUCCESS =
  "FETCH_TOKEN_SUMMARY_DATA_SUCCESS"
export const fetchTokenSummaryDataSuccess = createAction(
  FETCH_TOKEN_SUMMARY_DATA_SUCCESS
)

export const FETCH_TOKEN_TYPE_DATA_REQUEST = "FETCH_TOKEN_TYPE_DATA_REQUEST"
export const fetchTokenTypeDataRequest = createAction(
  FETCH_TOKEN_TYPE_DATA_REQUEST
)

export const FETCH_TOKEN_TYPE_DATA_SUCCESS = "FETCH_TOKEN_TYPE_DATA_SUCCESS"
export const fetchTokenTypeDataSuccess = createAction(
  FETCH_TOKEN_TYPE_DATA_SUCCESS
)

export const FETCH_API_CALL_SUMMARY_DATA_REQUEST =
  "FETCH_API_CALL_SUMMARY_DATA_REQUEST"
export const fetchApiCallSummaryDataRequest = createAction(
  FETCH_API_CALL_SUMMARY_DATA_REQUEST
)

export const FETCH_API_CALL_SUMMARY_DATA_SUCCESS =
  "FETCH_API_CALL_SUMMARY_DATA_SUCCESS"
export const fetchApiCallSummaryDataSuccess = createAction(
  FETCH_API_CALL_SUMMARY_DATA_SUCCESS
)

// report actions
export const FETCH_TOKEN_SUMMARY_REPORT_REQUEST =
  "FETCH_TOKEN_SUMMARY_REPORT_REQUEST"
export const fetchTokenSummaryReportRequest = createAction(
  FETCH_TOKEN_SUMMARY_REPORT_REQUEST
)

export const FETCH_TOKEN_SUMMARY_REPORT_SUCCESS =
  "FETCH_TOKEN_SUMMARY_REPORT_SUCCESS"
export const fetchTokenSummaryReportSuccess = createAction(
  FETCH_TOKEN_SUMMARY_REPORT_SUCCESS
)

export const FETCH_TOKEN_TYPE_REPORT_REQUEST = "FETCH_TOKEN_TYPE_REPORT_REQUEST"
export const fetchTokenTypeReportRequest = createAction(
  FETCH_TOKEN_TYPE_REPORT_REQUEST
)

export const FETCH_TOKEN_TYPE_REPORT_SUCCESS = "FETCH_TOKEN_TYPE_REPORT_SUCCESS"
export const fetchTokenTypeReportSuccess = createAction(
  FETCH_TOKEN_TYPE_REPORT_SUCCESS
)

export const FETCH_API_CALL_SUMMARY_REPORT_REQUEST =
  "FETCH_API_CALL_SUMMARY_REPORT_REQUEST"
export const fetchApiCallSummaryReportRequest = createAction(
  FETCH_API_CALL_SUMMARY_REPORT_REQUEST
)

export const FETCH_API_CALL_SUMMARY_REPORT_SUCCESS =
  "FETCH_API_CALL_SUMMARY_REPORT_SUCCESS"
export const fetchApiCallSummaryReportSuccess = createAction(
  FETCH_API_CALL_SUMMARY_REPORT_SUCCESS
)
