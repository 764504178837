import {
  FETCH_EVENTS_POSTING_LOG_REQUEST,
  FETCH_EVENTS_POSTING_LOG_SUCCESS,
  FETCH_EVENTS_POSTING_DETAIL_LOG_REQUEST,
  FETCH_EVENTS_POSTING_DETAIL_LOG_SUCCESS
} from "../actions/postingLogs";

const postingLogs = (
  state = {
    eventsPostingLogs: {
      log1_0: [],
      log2_0: [],
      log3_0: [],
      log4_0: []
    },
    eventPostingDetailLogs: [],
    isLogViewFetching: false,
    isLogDetailViewFetching: false
  },
  action
) => {
  const broadcasterKeyMapping = {
    "1.0": "log1_0",
    "2.0": "log2_0",
    "3.0": "log3_0",
    "4.0": "log4_0"
  };
  switch (action.type) {
    case FETCH_EVENTS_POSTING_LOG_REQUEST:
      return {
        ...state,
        isLogViewFetching: true
      };
    case FETCH_EVENTS_POSTING_LOG_SUCCESS:
      return {
        ...state,
        eventsPostingLogs: {
          ...state.eventsPostingLogs,
          [broadcasterKeyMapping[action.payload.pos_broadcaster]]:
            action.payload.data
        },
        isLogViewFetching: false
      };
    case FETCH_EVENTS_POSTING_DETAIL_LOG_REQUEST:
      return {
        ...state,
        isLogDetailViewFetching: true
      };
    case FETCH_EVENTS_POSTING_DETAIL_LOG_SUCCESS:
      return {
        ...state,
        eventPostingDetailLogs: action.payload.data,
        isLogDetailViewFetching: false
      };
    default:
      return state;
  }
};

export default postingLogs;

export const getEventsPostingLog = state => state.eventsPostingLogs;
export const getEventsPostingDetailLog = state => state.eventPostingDetailLogs;
export const getIsPostingLogViewFetching = state => state.isLogViewFetching;
export const getIsPostingLogDetailViewFetching = state =>
  state.isLogDetailViewFetching;
