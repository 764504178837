import * as actions from "../actions/tickets";
import {
  FETCH_TRACKED_LISTINGS_SUCCESS,
  FETCH_SOLD_LISTINGS_SUCCESS
} from "../actions/listings";

import {
  FETCH_RESALE_MEDIAN_TICKETS_SUCCESS,
  FETCH_RESALE_MEDIAN_TICKETS_COUNT_SUCCESS
} from "../actions/tickets";

const initalState = {
  selectedEvent: {},
  summary: {},
  groups: {},
  pctVenueAvail: null,
  tickets: {},
  validListings: {},
  soldListings: {},
  trackedListings: {},
  selectedEventFrom: null,
  resaleMedianListings: {
    medianListings: [],
    page: 1,
    totalListing: 0
  },
  totalResaleMedianHourlyListingCount: 0
};

const ticketsByEvent = (state = initalState, action) => {
  switch (action.type) {
    case actions.FETCH_TICKETS_BY_EVENTID_SUCCESS:
      return { ...state, ...action.payload };
    case actions.FETCH_TICKETS_BY_EVENTID_CLEAR:
      return (state = initalState);
    case actions.SAVE_SELECT_EVENT:
      return { ...state, selectedEvent: action.payload };
    case actions.SAVE_SELECTED_EVENT_FROM:
      return { ...state, selectedEventFrom: action.payload };
    case actions.CLEAR_SELECT_EVENT:
      return { ...state, selectedEvent: {} };
    case FETCH_TRACKED_LISTINGS_SUCCESS:
      return { ...state, trackedListings: action.payload };
    case FETCH_SOLD_LISTINGS_SUCCESS:
      return { ...state, soldListings: action.payload };
    case FETCH_RESALE_MEDIAN_TICKETS_SUCCESS:
      return { ...state, resaleMedianListings: action.payload };
    case FETCH_RESALE_MEDIAN_TICKETS_COUNT_SUCCESS:
      return { ...state, totalResaleMedianHourlyListingCount: action.payload };

    default:
      return state;
  }
};

export default ticketsByEvent;

export const getTicketsSummary = state => state.summary;
export const getTicketsGroups = state => state.groups;
export const getTickets = state => state.tickets;
export const getSelectedTicket = state => state.selectedEvent;
export const getSelectedEventFrom = state => state.selectedEventFrom;
export const getValidListings = state => state.validListings;
export const getTrackedListings = state => state.trackedListings;
export const getSoldListings = state => state.soldListings;
export const getPctVenueAvail = state => state.pctVenueAvail;
export const getResaleMedianListings = state => state.resaleMedianListings;
export const getResaleMedianHourlyListingCount = state =>
  state.totalResaleMedianHourlyListingCount;
