import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { GlobalDebug } from "./utils/remove-consoles";
import "./App.scss";
import configureStore from "./store";
import ErrorModalContainer from "./components/ErrorModal";
import ToastrModalContainer from "./components/Toastr";
import { GoogleOAuthProvider } from "@react-oauth/google";

const clientId =
  "834796756843-fgpmpr1o775lvpj0uicdbd9ae7n1uoqo.apps.googleusercontent.com";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const DefaultLayout = Loadable({
  loader: () => import("./containers/DefaultLayout"),
  loading
});

//Pages
const Login = Loadable({
  loader: () => import("./views/Login"),
  loading
});

const Register = Loadable({
  loader: () => import("./views/Register/Register"),
  loading
});
class App extends Component {
  componentDidMount() {
    process.env.NODE_ENV === "production" && GlobalDebug(false);
  }

  render() {
    const { store, persistor } = configureStore();
    return (
      <GoogleOAuthProvider clientId={clientId}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <ToastrModalContainer />
            <ErrorModalContainer />
            <HashRouter>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  component={Login}
                />
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  component={Register}
                />
                <Route path="/" name="Home" component={DefaultLayout} />
              </Switch>
            </HashRouter>
          </PersistGate>
        </Provider>
      </GoogleOAuthProvider>
    );
  }
}

export default App;
