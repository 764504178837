import * as actions from "../actions/listings";

const initialState = {
  email: "",
  eventInfo: {},
  validPromos: [],
  userPurchaseSummary: {},
  pdfAttachement: null,
  pdfDownloaded: null,
  orderFullFillMent: {},
  evenuePdf: null,
  broadCast_unBroadCast_data: []
};

const initialListing = {
  ids: [],
  dict: {},
  totalListing: 0,
  ordersCostCount: {
    redOrdersTotalCost: 0,
    purpleOrdersTotalCost: 0,
    greenOrdersTotalCost: 0,
    costForPurpleOrder: [],
    costForRedOrder: [],
    costForGreenOrder: []
  }
};

const listings = (
  state = {
    salesStatisticsFetching: false,
    salesByMarketFetching: false,
    isSalesBySecondaryMarketFetching: false,
    isSoldStatisticsFetching: false,
    isAllOrdersFetching: false,
    salesByVenueFetching: false,
    salesByHourFetching: false,
    salesByPerformerFetching: false,
    openSaleslistings: initialListing,
    openTransferslistings: initialListing,
    upcomingOpenOrders: initialListing,
    purchasedTicketInfo: initialState,
    orderFlowListing: initialListing,
    trackedListing: [],
    cloakListing: [],
    allOrders: {
      records: [],
      total: 0,
      page: 1
    },
    brokerUsers: [
      { label: "Select user", value: "" },
      { label: "Morris Z", value: "66448508b0be34fcb68dfc9e" },
      { label: "Neil", value: "66b3513e53c2a87fec021292" }
    ],
    adminUsers: [
      { label: "Select user", value: "" },
      { label: "Morris Z", value: "66448508b0be34fcb68dfc9e" }
    ],
    soldStatisticsLogs: {
      soldList: [],
      preSaleList: [],
      dayOfSaleList: [],
      reSaleList: [],
      totalOrders: 0,
      totalProfit: 0,
      totalSales: 0,
      totalTicketsSold: 0,
      averageProfitMargin: 0
    },
    salesStatisticsLog: {
      soldList: [],
      preSaleList: [],
      dayOfSaleList: [],
      totalOrders: 0,
      totalProfit: 0,
      totalSales: 0,
      totalTicketsSold: 0,
      averageProfitMargin: 0
    },
    slipOrders: [],
    oneTicketOrders: {
      orders: [],
      page: 1,
      total: 0
    },
    upComingOrders: [],
    salesByMarket: {
      soldList: [],
      preSaleList: [],
      dayOfSaleList: [],
      totalOrders: 0,
      totalProfit: 0,
      totalSales: 0,
      totalTicketsSold: 0,
      averageProfitMargin: 0
    },
    salesBySecondaryMarketType: {
      averageProfitMargin: 0,
      soldList: [],
      totalMarketSales: [],
      totalOrders: 0,
      totalProfit: 0,
      totalProfitMargin: 0,
      totalSales: 0
    },
    salesByVenue: {
      topVenueList: [],
      venueSaleProfitList: []
    },
    salesByHour: {
      data: []
    },
    salesByPerformer: {
      topPerformerList: [],
      performerSaleProfitList: []
    },
    lastSale: {
      saleTime: "",
      minitues: 0
    }
  },
  action
) => {
  switch (action.type) {
    case actions.REMOVE_BUSTED_ORDER_PURPLE:
      return {
        ...state,
        openSaleslistings: {
          ...state.openSaleslistings,
          sortedTempErrorListings: state.openSaleslistings.sortedTempErrorListings.filter(
            listing => listing.listingId !== action.payload
          )
        }
      };
    case actions.REMOVE_BUSTED_ORDER_RED:
      return {
        ...state,
        openSaleslistings: {
          ...state.openSaleslistings,
          sortedRedListings: state.openSaleslistings.sortedRedListings.filter(
            listing => listing.listingId !== action.payload
          )
        }
      };
    case actions.REMOVE_BUSTED_ORDER_WORKFLOW:
      return {
        ...state,
        orderFlowListing: {
          ...state.orderFlowListing,
          sortedOverFlowListings: state.orderFlowListing.sortedOverFlowListings.map(
            listing => {
              if (listing.listingId !== action.payload.listingId)
                return { ...listing };
              return { ...listing, ...action.payload, isBusted: true };
            }
          )
        }
      };
    case actions.FETCH_OPEN_SALES_SUCCESS:
      return { ...state, openSaleslistings: action.payload };
    case actions.CLEAR_OPEN_SALES_LISTINGS:
      return { ...state, openSaleslistings: initialListing };
    case actions.FETCH_OPEN_TRANSFERS_SUCCESS:
      return { ...state, openTransferslistings: action.payload };
    case actions.CLEAR_OPEN_TRANSFER_LISTINGS:
      return { ...state, openTransferslistings: initialListing };
    case actions.FETCH_UPCOMING_OPEN_ORDERS_SUCCESS:
      return { ...state, upcomingOpenOrders: action.payload };
    case actions.FETCH_SIMULATE_TRACKED_LISTING_SUCCESS:
      return { ...state, trackedListing: action.payload };
    case actions.FETCH_ORDER_FLOW_SUCCESS:
      return { ...state, orderFlowListing: action.payload };
    case actions.FETCH_PDF_ATTACHMENT_SUCCESS:
      return { ...state, pdfAttachement: action.payload };
    case actions.FETCH_EVENUE_PDF_SUCCESS:
      return { ...state, evenuePdf: action.payload };
    case actions.FETCH_PDF_DOWNLOADED_SUCCESS:
      return { ...state, pdfDownloaded: action.payload };
    case actions.FETCH_ORDER_FULLFILLMENT_SUCCESS:
      return { ...state, orderFullFillMent: action.payload };
    case actions.FETCH_CLOAK_LISTING_SUCCESS:
      return { ...state, cloakListing: action.payload };
    case actions.UPDATE_PURCHASE_TICKET_INFO:
      return {
        ...state,
        purchasedTicketInfo: {
          ...state.purchasedTicketInfo,
          eventInfo: action.payload
        }
      };
    case actions.TRY_BUY_AGAIN_REQUEST:
      return {
        ...state,
        purchasedTicketInfo: {
          ...state.purchasedTicketInfo,
          eventInfo: action.payload
        }
      };
    case actions.FETCH_USER_SUMMARY_SUCCESS:
      return {
        ...state,
        purchasedTicketInfo: {
          ...state.purchasedTicketInfo,
          userPurchaseSummary: action.payload
        }
      };
    // case actions.RESET_EMAIL_PASSWORD_SUCCESS:
    // return {
    //   ...state,

    // }
    case actions.TRY_BUY_AGAIN_SUCCESS:
    case actions.TRY_BUY_AGAIN_FAIL:
      return {
        ...state,
        purchasedTicketInfo: {
          ...state.purchasedTicketInfo,
          email: action.payload.email,
          password: action.payload.password,
          one_ticket_password: action.payload.one_ticket_password,
          phoneNumber: action.payload.phoneNumber,
          address: action.payload.address,
          name: action.payload.name,
          capOne: action.payload.capOne,
          amex: action.payload.amex,
          comdata: action.payload.comdata,
          citi1: action.payload.citi1,
          citi2: action.payload.citi2,
          eventInfo: {
            ...state.purchasedTicketInfo.eventInfo,
            presales: action.payload.presales,
            promos: action.payload.promos
          },
          validPromos: action.payload.validPromos,
          locked: action.payload.locked,
          globalPromos: action.payload.globalPromos,
          partialView: action.payload.partialView,
          obstructedView: action.payload.partialView,
          limitedView: action.payload.partialView,
          isComplicatedOrder: action.payload.isComplicatedOrder,
          isFromSkybox2: action.payload.isFromSkybox2,
          isFromBroker: action.payload.isFromBroker,
          brokerRole: action.payload.brokerRole,
          posUsername: action.payload.posUsername,
          complicatedOrderNote: action.payload.complicatedOrderNote,
          showOnSalePassCodes: action.payload.showOnSalePassCodes
        }
      };
    case actions.RESET_PURCHASED_TICKET_INFO:
      return { ...state, purchasedTicketInfo: initialState };
    case actions.MARK_ORDER_AS_COMPLICATED_SUCCESS:
      return {
        ...state,
        purchasedTicketInfo: {
          ...state.purchasedTicketInfo,
          isComplicatedOrder: action.payload
        }
      };
    case actions.FETCH_SOLD_STATISTICS_SUCCESS:
      return {
        ...state,
        isSoldStatisticsFetching: false,
        soldStatisticsLogs: action.payload
      };
    case actions.FETCH_SOLD_STATISTICS_BEFORE_REQUEST:
      return {
        ...state,
        isSoldStatisticsFetching: true,
        soldStatisticsLogs: {
          soldList: [],
          preSaleList: [],
          dayOfSaleList: [],
          reSaleList: [],
          totalOrders: 0,
          totalProfit: 0,
          totalSales: 0,
          totalTicketsSold: 0,
          averageProfitMargin: 0
        }
      };
    case actions.FETCH_ALL_ORDERS_BEFORE_REQUEST:
      return {
        ...state,
        isAllOrdersFetching: true
      };
    case actions.FETCH_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        isAllOrdersFetching: false,
        allOrders: action.payload
      };
    case actions.FETCH_SALES_STATISTICS_REQUEST:
      return { ...state, salesStatisticsFetching: true };
    case actions.FETCH_SALES_STATISTICS_SUCCESS:
      return {
        ...state,
        salesStatisticsFetching: false,
        salesStatisticsLog: action.payload
      };
    case actions.FETCH_BROAD_UNBROAD_CAST_EVENT_DAYS_SUCCESS:
      return {
        ...state,
        broadCast_unBroadCast_data: action.payload
      };
    case actions.FETCH_SLIPORDER_SUCCESS:
      return { ...state, slipOrders: action.payload };
    case actions.FETCH_ONE_TICKET_ORDERS_SUCCESS:
      return {
        ...state,
        oneTicketOrders: {
          orders: action.payload.orders,
          page: action.payload.page,
          total: action.payload.totalRow
        }
      };
    case actions.FETCH_UPCOMINGORDER_SUCCESS:
      return { ...state, upComingOrders: action.payload };
    case actions.FETCH_SALES_BY_MARKET_REQUEST:
      return { ...state, salesByMarketFetching: true };
    case actions.FETCH_SALES_BY_MARKET_SUCCESS:
      return {
        ...state,
        salesByMarketFetching: false,
        salesByMarket: action.payload
      };
    case actions.FETCH_SALES_BY_SECONDARY_MARKET_REQUEST:
      return { ...state, isSalesBySecondaryMarketFetching: true };
    case actions.FETCH_SALES_BY_SECONDARY_MARKET_SUCCESS:
      return {
        ...state,
        isSalesBySecondaryMarketFetching: false,
        salesBySecondaryMarketType: action.payload
      };
    case actions.FETCH_SALES_BY_VENUE_REQUEST:
      return { ...state, salesByVenueFetching: true };
    case actions.FETCH_SALES_BY_VENUE_SUCCESS:
      return {
        ...state,
        salesByVenueFetching: false,
        salesByVenue: action.payload
      };
    case actions.FETCH_SALES_BY_HOUR_REQUEST:
      return { ...state, salesByHourFetching: true };
    case actions.FETCH_SALES_BY_HOUR_SUCCESS:
      return {
        ...state,
        salesByHourFetching: false,
        salesByHour: action.payload
      };
    case actions.FETCH_SALES_BY_PERFORMER_REQUEST:
      return { ...state, salesByPerformerFetching: true };
    case actions.FETCH_SALES_BY_PERFORMER_SUCCESS:
      return {
        ...state,
        salesByPerformerFetching: false,
        salesByPerformer: action.payload
      };

    case actions.FETCH_LAST_SALE_SUCCESS:
      return {
        ...state,
        lastSale: action.payload
      };
    default:
      return state;
  }
};

export default listings;

export const getOpenSalesListings = state => state.openSaleslistings;
export const getOpenTransfersListings = state => state.openTransferslistings;
export const getUpcomingOpenOrders = state => state.upcomingOpenOrders;
export const getPurchasedTicketInfo = state => state.purchasedTicketInfo;
export const getSimulateTrackedListing = state => state.trackedListing;
export const getOrderFlowListings = state => state.orderFlowListing;
export const getPDFAttachment = state => state.pdfAttachement;
export const getPDFDownloaded = state => state.pdfDownloaded;
export const getCloakListings = state => state.cloakListing;
export const getSoldStatisticsLog = state => state.soldStatisticsLogs;
export const getOrderFullFillMent = state => state.orderFullFillMent;
export const getEvenuePDF = state => state.evenuePdf;
export const getBroadCastUnbroadCastData = state =>
  state.broadCast_unBroadCast_data;
export const getSlipOrders = state => state.slipOrders;
export const getUpComingOrder = state => state.upComingOrders;
export const getSalesStatisticsLog = state => state.salesStatisticsLog;
export const getIsSalesStatisticsFetching = state =>
  state.salesStatisticsFetching;
export const getSalesByMarketType = state => state.salesByMarket;
export const getSalesBySecondaryMarketType = state =>
  state.salesBySecondaryMarketType;
export const getIsSalesByMarketFetching = state => state.salesByMarketFetching;
export const getIsSalesBySecondaryMarketFetching = state =>
  state.isSalesBySecondaryMarketFetching;
export const getIsSoldStatisticsFetching = state =>
  state.isSoldStatisticsFetching;
export const getIsAllOrdersFetching = state => state.isAllOrdersFetching;
export const getAllOrders = state => state.allOrders;
export const getSalesByVenue = state => state.salesByVenue;
export const getIsSalesByVenueFetching = state => state.salesByVenueFetching;
export const getSalesByHour = state => state.salesByHour;
export const getIsSalesByHourFetching = state => state.salesByHourFetching;
export const getSalesByPerformer = state => state.salesByPerformer;
export const getIsSalesByPerformerFetching = state =>
  state.salesByPerformerFetching;
export const getLastSaleTime = state => state.lastSale;
export const getOneTicketOrders = state => state.oneTicketOrders;
export const getBrokerUsers = state => state.brokerUsers;
export const getAdminUsers = state => state.adminUsers;
