import { createAction } from "redux-actions";

// for Order Status Listing
export const FETCH_ORDER_STATUS_REQUEST = "FETCH_ORDER_STATUS_REQUEST";
export const fetchOrderStatusRequest = createAction(FETCH_ORDER_STATUS_REQUEST);

export const FETCH_ORDER_STATUS_SUCCESS = "FETCH_ORDER_STATUS_SUCCESS";
export const fetchOrderStatusSuccess = createAction(FETCH_ORDER_STATUS_SUCCESS);

export const CREATE_TJ_ORDER = "CREATE_TJ_ORDER";
export const createTJOrderRequest = createAction(CREATE_TJ_ORDER);

export const GET_CUSTOMER_DISPLAY = "GET_CUSTOMER_DISPLAY";
export const getCustomerDisplayRequest = createAction(GET_CUSTOMER_DISPLAY);

export const GET_CUSTOMER_DISPLAY_REQUEST = "GET_CUSTOMER_DISPLAY_REQUEST";
export const getCustomerDisplayBeforeRequest = createAction(
  GET_CUSTOMER_DISPLAY_REQUEST
);

export const GET_CUSTOMER_DISPLAY_SUCCESS = "GET_CUSTOMER_DISPLAY_SUCCESS";
export const getCustomerDisplayRequestSuccess = createAction(
  GET_CUSTOMER_DISPLAY_SUCCESS
);

export const CREATE_ORDER_BEFORE_REQUEST = "CREATE_ORDER_BEFORE_REQUEST";
export const createTJOrderBeforeRequest = createAction(
  CREATE_ORDER_BEFORE_REQUEST
);

export const CREATE_ORDER_REQUEST_SUCCESS = "CREATE_ORDER_REQUEST_SUCCESS";
export const createTJOrderRequestSuccess = createAction(
  CREATE_ORDER_REQUEST_SUCCESS
);

export const GET_MANUAL_ORDERS = "GET_MANUAL_ORDERS";
export const getManualOrders = createAction(GET_MANUAL_ORDERS);

export const MANUAL_ORDERS_BEFORE_REQUEST = "MANUAL_ORDERS_BEFORE_REQUEST";
export const manualOrdersBeforeRequest = createAction(
  MANUAL_ORDERS_BEFORE_REQUEST
);

export const GET_MANUAL_ORDERS_SUCCESS = "GET_MANUAL_ORDERS_SUCCESS";
export const getManualOrdersSuccess = createAction(GET_MANUAL_ORDERS_SUCCESS);

export const SAVE_BROKER_ORDER_REQUEST = "SAVE_BROKER_ORDER_REQUEST";
export const saveBrokerOrderRequest = createAction(SAVE_BROKER_ORDER_REQUEST);

export const SAVE_BROKER_ORDER_BEFORE_REQUEST =
  "SAVE_BROKER_ORDER_BEFORE_REQUEST";
export const saveBrokerOrderBeforeRequest = createAction(
  SAVE_BROKER_ORDER_BEFORE_REQUEST
);

export const SAVE_BROKER_ORDER_SUCCESS = "SAVE_BROKER_ORDER_SUCCESS";
export const saveBrokerOrderRequestSuccess = createAction(
  SAVE_BROKER_ORDER_SUCCESS
);
export const SAVE_BROKER_ORDER_CHANGE_STATUS =
  "SAVE_BROKER_ORDER_CHANGE_STATUS";
export const saveBrokerOrderChangeStatus = createAction(
  SAVE_BROKER_ORDER_CHANGE_STATUS
);

export const DELETE_MANUAL_ORDER = "DELETE_MANUAL_ORDER";
export const deleteManualOrderRequest = createAction(DELETE_MANUAL_ORDER);

export const DELETE_MANUAL_ORDER_BEFORE_REQUEST =
  "DELETE_MANUAL_ORDER_BEFORE_REQUEST";
export const deleteManualOrderBeforeRequest = createAction(
  DELETE_MANUAL_ORDER_BEFORE_REQUEST
);

export const DELETE_MANUAL_ORDER_SUCCESS = "DELETE_MANUAL_ORDER_SUCCESS";
export const deleteManualOrderSuccess = createAction(
  DELETE_MANUAL_ORDER_SUCCESS
);

export const DELETE_MANUAL_ORDER_FILTER = "DELETE_MANUAL_ORDER_FILTER";
export const deleteManualOrderFilter = createAction(DELETE_MANUAL_ORDER_FILTER);
