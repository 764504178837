export const VIP = "vip"
export const PARKING = "parking"
export const TOUR = "tour"
export const PRACTICE = "practice"

export const stockTypes = [
  "MOBILE_TRANSFER",
  "ELECTRONIC",
  "MOBILE_SCREENCAP",
  "FLASH",
  "PAPERLESS",
  "PAPERLESS_CARD",
  "HARD"
]
