import { createAction } from "redux-actions";

export const FETCH_EVENTS_POSTING_LOG_REQUEST =
  "FETCH_EVENTS_POSTING_LOG_REQUEST";
export const fetchEventsPostingLogRequest = createAction(
  FETCH_EVENTS_POSTING_LOG_REQUEST
);

export const FETCH_EVENTS_POSTING_LOG_SUCCESS =
  "FETCH_EVENTS_POSTING_LOG_SUCCESS";
export const fetchEventsPostingLogSuccess = createAction(
  FETCH_EVENTS_POSTING_LOG_SUCCESS
);

export const FETCH_EVENTS_POSTING_DETAIL_LOG_REQUEST =
  "FETCH_EVENTS_POSTING_DETAIL_LOG_REQUEST";
export const fetchEventPostingDetailLogsRequest = createAction(
  FETCH_EVENTS_POSTING_DETAIL_LOG_REQUEST
);

export const FETCH_EVENTS_POSTING_DETAIL_LOG_SUCCESS =
  "FETCH_EVENTS_POSTING_DETAIL_LOG_SUCCESS";
export const fetchEventPostingDetailLogsSuccess = createAction(
  FETCH_EVENTS_POSTING_DETAIL_LOG_SUCCESS
);
