import { restApiClient } from "."

export const fetchTokenSummaryData = payload => {
  return restApiClient.get(`/getTokenSummaryData`, {
    params: payload
  })
}

export const fetchTokenTypeData = payload => {
  return restApiClient.get(`/getTokenTypeData`, {
    params: payload
  })
}

export const fetchApiCallSummaryData = payload => {
  return restApiClient.get(`/getApiCallSummaryData`, {
    params: payload
  })
}

export const fetchTokenSummaryReport = payload => {
  return restApiClient.get(`/getTokenSummaryReport`, {
    params: payload
  })
}

export const fetchTokenTypeReport = payload => {
  return restApiClient.get(`/getTokenTypeReport`, {
    params: payload
  })
}

export const fetchApiCallSummaryReport = payload => {
  return restApiClient.get(`/getApiCallSummaryReport`, {
    params: payload
  })
}
