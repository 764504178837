import { createAction } from "redux-actions";

export const FFETCH_TICKETS_BY_EVENTID_REQUEST =
  "FFETCH_TICKETS_BY_EVENTID_REQUEST";
export const FFETCH_TICKETS_BY_EVENTID_STOP_REQUEST =
  "FFETCH_TICKETS_BY_EVENTID_STOP_REQUEST";
export const FETCH_TICKETS_BY_EVENTID_FAILURE =
  "FETCH_TICKETS_BY_EVENTID_FAILURE";
export const FETCH_TICKETS_BY_EVENTID_SUCCESS =
  "FETCH_TICKETS_BY_EVENTID_SUCCESS";
export const FETCH_TICKETS_BY_EVENTID_CLEAR = "FETCH_TICKETS_BY_EVENTID_CLEAR";

export const fetchTicketsByEventIdRequest = createAction(
  FFETCH_TICKETS_BY_EVENTID_REQUEST
);
export const fetchTicketsByEventIdFailure = createAction(
  FETCH_TICKETS_BY_EVENTID_FAILURE
);
export const fetchTicketsByEventIdSuccess = createAction(
  FETCH_TICKETS_BY_EVENTID_SUCCESS
);

export const fetchTicketsByEventIdClear = createAction(
  FETCH_TICKETS_BY_EVENTID_CLEAR
);

export const fetchTicketsByEventIdStopRequest = createAction(
  FFETCH_TICKETS_BY_EVENTID_STOP_REQUEST
);

//Selected event
export const SAVE_SELECT_EVENT = "SAVE_SELECT_EVENT";
export const SAVE_SELECTED_EVENT_FROM = "SAVE_SELECTED_EVENT_FROM";
export const CLEAR_SELECT_EVENT = "CLEAR_SELECT_EVENT";

export const saveSelectEvent = createAction(SAVE_SELECT_EVENT);
export const saveSelectedEventFrom = createAction(SAVE_SELECTED_EVENT_FROM);
export const clearSelectEvent = createAction(CLEAR_SELECT_EVENT);

//resale mediam listing request

export const FETCH_RESALE_MEDIAN_TICKETS_REQUEST =
  "FETCH_RESALE_MEDIAN_TICKETS_REQUEST";
export const FETCH_RESALE_MEDIAN_TICKETS_SUCCESS =
  "FETCH_RESALE_MEDIAN_TICKETS_SUCCESS";

export const fetchResaleMedianTicketsRequest = createAction(
  FETCH_RESALE_MEDIAN_TICKETS_REQUEST
);
export const fetchResaleMedianTicketsSuccess = createAction(
  FETCH_RESALE_MEDIAN_TICKETS_SUCCESS
);

export const FETCH_RESALE_MEDIAN_TICKETS_COUNTS_REQUEST =
  "FETCH_RESALE_MEDIAN_TICKETS_COUNTS_REQUEST";
export const FETCH_RESALE_MEDIAN_TICKETS_COUNT_SUCCESS =
  "FETCH_RESALE_MEDIAN_TICKETS_COUNT_SUCCESS";

export const fetchResaleMedianTicketsCountRequest = createAction(
  FETCH_RESALE_MEDIAN_TICKETS_COUNTS_REQUEST
);
export const fetchResaleMedianTicketsCountSuccess = createAction(
  FETCH_RESALE_MEDIAN_TICKETS_COUNT_SUCCESS
);

export const ARCHIVE_MEDIAN_TICKETS_REQUEST = "ARCHIVE_MEDIAN_TICKETS_REQUEST";

export const archiveMedianListingRequest = createAction(
  ARCHIVE_MEDIAN_TICKETS_REQUEST
);
