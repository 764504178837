import React from "react";

export const TYPE_SALE_LISTING = "sale";
export const TYPE_TRANSFER_LISTING = "transfer";
export const TYPE_PROBLEM_BUYING_LISTING = "problemBuying";

//problem buying reason
export const PROBLEM_BUYING_REASON = [
  "Promo Code Needed",
  "Seat Not Available",
  "TM App Error",
  "It’s not you It’s us",
  "Other",
  "Will Call Only",
  "Price does not Match",
  "Error Code: 0001"
];

export const TEMP_ERRORS = [
  "Error Code: 0001",
  "TM App Error",
  "It’s not you It’s us"
];

export const STEP2_INFO =
  'Is This Ticket Actually Purchased? If So Please Click "Ticket purchased" Button';

export const STEP3_INFO = (
  <div>
    <span>
      <b>[A]</b> The PO From 1Ticket Is Not Attached To Invoice, Please Check On
      1Ticket That The PDF Was Downloaded & Listing Created In Skybox.
      Additionally, Check That The Ticket Details Of The 1Ticket Listing Match
      The Sold Ticket (quantity, section, row, event date & time).
    </span>
    <br />
    <br />
    <span>
      <b>[B]</b> If A Different Section or Row Purchased, Then please hit Manual
      PO Transfer listed below and select appropriate 1Ticket listing and press
      Submit.
    </span>
  </div>
);

export const COLOR = [
  "#86b1eb",
  "#434348",
  "#8ef17e",
  "#f2a55e",
  "red",
  "#008000",
  "#00008B",
  "#800080",
  "#FF1493",
  "#FF00FF",
  "#4B0082",
  "#A52A2A",
  "#D2691E",
  "#DAA520",
  "#696969",
  "#FF9C33",
  "#ECFF33",
  "#99FF33",
  "#33FF9C",
  "#3386FF"
];

export const POINTSTYLE = [
  "rectRot",
  "rect",
  "triangle",
  "star",
  "circle",
  "rectRot",
  "rect",
  "triangle",
  "star",
  "circle",
  "rectRot",
  "rect",
  "triangle",
  "star",
  "circle",
  "rectRot",
  "rect",
  "triangle",
  "star",
  "circle"
];

// Secondary Market Locations
export const SECONDARY_MARKET_LOCATION = [
  "Stubhub",
  "Vivid Seats",
  "Seatgeek",
  "Ticket Network",
  "Tickpick",
  "Viagogo"
];

// Customer Display Name
export const CUSTOMER_DISPLAY_NAME = [
  "seatgeek",
  "ticketnetwork",
  "vividseats",
  "viagogo",
  "gametime",
  "tickpick",
  "stubhub",
  "ticketevolution"
];

export const MARKETS = [
  {
    label: "AXS",
    value: "AXS"
  },
  {
    label: "E-Venue",
    value: "eVenue"
  },
  {
    label: "TM",
    value: "TM"
  },
  {
    label: "Metopera",
    value: "metopera"
  },
  {
    label: "NYC Ballet",
    value: "nycballet"
  },
  {
    label: "MLB",
    value: "mlb"
  },
  {
    label: "SeatGeek",
    value: "seatgeek_broadway"
  },
  {
    label: "SeatGeek-MLB",
    value: "seatgeekMLB"
  },
  {
    label: "Broadway.com",
    value: "broadway.com"
  },
  {
    label: "Broadway Harry Potter",
    value: "broadway_harrypotter"
  },
  {
    label: "Hudson Broadway",
    value: "hudson_broadway"
  },
  {
    label: "NY City Center",
    value: "nycitycenter"
  },
  {
    label: "Broadway Direct",
    value: "broadwayDirect"
  }
];

export const marketIcons = [
  {
    name: ["nycitycenter"],
    tooltip: "New York City Center Event",
    alt: "NYC Event",
    isPrimary: true,
    placement: "left",
    icon: require("../assets/img/nyc.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["eVenue"],
    tooltip: "Evenue Event",
    alt: "NYC Event",
    isPrimary: true,
    placement: "left",
    icon: "fa fa-venus-mars",
    img: false,
    class: "evenueIcon"
  },
  {
    name: ["AXS"],
    tooltip: "AXS Event",
    alt: "NYC Event",
    isPrimary: true,
    placement: "left",
    icon: "fa fa-globe",
    img: false,
    class: "axsIcon"
  },
  {
    name: ["metopera"],
    tooltip: "Metopera Event",
    alt: "NYC Event",
    isPrimary: true,
    placement: "left",
    icon: "fa fa-meetup",
    img: false,
    class: "metoperaIcon"
  },
  {
    name: ["seatgeekMLB"],
    tooltip: "Seatgeek MLB Event",
    alt: "Seatgeek MLB Event",
    isPrimary: true,
    placement: "left",
    icon: require("../assets/img/seatgeek-mlb.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["nycballet"],
    tooltip: "NYC Ballet Event",
    alt: "NYC Ballet Event",
    isPrimary: true,
    placement: "left",
    icon: "fa fa-hacker-news",
    img: false,
    class: "nycbIcon"
  },
  {
    name: ["mlb"],
    tooltip: "MLB Event",
    alt: "MLB Event",
    isPrimary: true,
    placement: "left",
    icon: "fa fa-medium",
    img: false,
    class: "mlbIcon"
  },
  {
    name: ["seatgeek_broadway"],
    tooltip: "Seatgeek Broadway Event",
    alt: "Seatgeek Broadway Event",
    isPrimary: true,
    placement: "left",
    icon: require("../assets/img/seatgeek_broadway.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["seatgeek"],
    tooltip: "Seatgeek Event",
    alt: "Seatgeek Event",
    isPrimary: true,
    placement: "left",
    icon: require("../assets/img/seetgreek.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["broadway.com"],
    tooltip: "Broadway.com Event",
    alt: "Broadway.com Event",
    isPrimary: true,
    placement: "left",
    icon: require("../assets/img/broadwaycom.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["Stubhub", "StubHub (1Ticket)", "stubhub"],
    tooltip: "Stubhub Event",
    marketName: "Stubhub",
    alt: "Stubhub Event",
    isPrimary: false,
    placement: "left",
    icon: require("../assets/img/stubhub.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["SeatGeek", "SeatGeek (1Ticket)"],
    tooltip: "Seatgeek Event",
    marketName: "SeatGeek",
    alt: "Seatgeek Event",
    isPrimary: false,
    placement: "left",
    icon: require("../assets/img/seetgreek.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["Gametime", "Gametime (1Ticket)"],
    tooltip: "GameTime Event",
    marketName: "Gametime",
    alt: "GameTime Event",
    isPrimary: false,
    placement: "left",
    icon: require("../assets/img/gametime.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["TickPick", "TickPick (1Ticket)"],
    tooltip: "Tickpick Event",
    marketName: "TickPick",
    alt: "Tickpick Event",
    isPrimary: false,
    placement: "left",
    icon: require("../assets/img/tickpick.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["Ticket Network", "TicketNetwork (1Ticket)", "ticketNetwork"],
    tooltip: "TicketNetWork Event",
    marketName: "Ticket Network",
    alt: "TicketNetWork Event",
    isPrimary: false,
    placement: "left",
    icon: require("../assets/img/ticketNetwork.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["Vivid Seats", "VividSeats (1Ticket)", "vivid"],
    tooltip: "VividSeats Event",
    marketName: "Vivid Seats",
    alt: "VividSeats Event",
    isPrimary: false,
    placement: "left",
    icon: require("../assets/img/vividSeat.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["broadway_harrypotter"],
    tooltip: "Broadway Harry Potter Event",
    alt: "Broadway Harry Potter Event",
    isPrimary: true,
    placement: "left",
    icon: require("../assets/img/broadway-harry-potter.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["hudson_broadway"],
    tooltip: "Hudson Broadway",
    alt: "Hudson Broadway",
    isPrimary: true,
    placement: "left",
    icon: require("../assets/img/hudson-broadway.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["Ticket Evolution", "Ticket Evolution (1Ticket)"],
    tooltip: "Ticket Evolution Event",
    marketName: "Ticket Evolution",
    alt: "Ticket Evolution",
    isPrimary: false,
    placement: "left",
    icon: require("../assets/img/ticketEvolution.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["Go tickets", "Go tickets"],
    tooltip: "Go tickets Event",
    marketName: "Go tickets",
    alt: "Go tickets",
    isPrimary: false,
    placement: "left",
    icon: require("../assets/img/go_tickets.png"),
    img: true,
    class: "marketImg"
  },
  {
    name: ["broadwayDirect"],
    tooltip: "Broadway Direct",
    alt: "Broadway Direct",
    isPrimary: true,
    placement: "left",
    icon: require("../assets/img/broadwayDirect.png"),
    img: true,
    class: "marketImg"
  }
];

export const SALE_TYPE = [
  {
    label: "TM Sales",
    value: "TM"
  },
  {
    label: "E-Venue Sales",
    value: "eVenue"
  },
  {
    label: "Pre Sales",
    value: "preSales"
  },
  {
    label: "Day Of Sales",
    value: "dayOfSales"
  },
  {
    label: "Metopera",
    value: "metopera"
  },
  {
    label: "NYC Ballet",
    value: "nycballet"
  },
  {
    label: "MLB",
    value: "mlb"
  },
  {
    label: "SeatGeek",
    value: "seatgeek_broadway"
  },
  {
    label: "SeatGeek-MLB",
    value: "seatgeekMLB"
  },
  {
    label: "Broadway.com",
    value: "broadway.com"
  },
  {
    label: "Broadway Harry Potter",
    value: "broadway_harrypotter"
  },
  {
    label: "Hudson Broadway",
    value: "hudson_broadway"
  },
  {
    label: "Broadway Direct",
    value: "broadwayDirect"
  }
];

export const POS2_0 = "automatiq2_0";

export const POS_TYPE = [
  {
    label: "Automatiq - 1.0",
    value: "1.0"
  },
  {
    label: "Automatiq - 2.0",
    value: "2.0"
  },
  {
    label: "Admins automated - 3.0",
    value: "automatedAdmin"
  },
  {
    label: "Admins daytrade - 4.0",
    value: "dayTradeAdmin"
  },
  {
    label: "Neil - 4.0",
    value: "brokerDayTrade"
  }
];

//Ticket Master url
export const TICKETMASTERURL = "https://www1.ticketmaster.com/event/";
