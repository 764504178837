/* eslint-disable no-unused-vars */
import * as actions from "../actions/orderStatus";

const initialState = {
  eventInfo: []
};

const initialListing = {
  ids: [],
  dict: {}
};

const listings = (
  state = {
    orderStatuslistings: {},
    isCreatingOrder: false,
    isFetchingCustomers: false,
    customerDisplayNames: [],
    isFetchingManualOrders: false,
    manualOrders: [],
    isProcessingManualOrder: false,
    isDeletingManualOrder: false
  },
  action
) => {
  switch (action.type) {
    case actions.FETCH_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        orderStatuslistings: action.payload
      };
    case actions.CREATE_ORDER_BEFORE_REQUEST:
      return {
        ...state,
        isCreatingOrder: true
      };
    case actions.CREATE_ORDER_REQUEST_SUCCESS:
      return {
        ...state,
        isCreatingOrder: false
      };
    case actions.GET_CUSTOMER_DISPLAY_REQUEST:
      return {
        ...state,
        isFetchingCustomers: true
      };
    case actions.GET_CUSTOMER_DISPLAY_SUCCESS:
      return {
        ...state,
        isFetchingCustomers: false,
        customerDisplayNames: action.payload
      };
    case actions.MANUAL_ORDERS_BEFORE_REQUEST:
      return {
        ...state,
        isFetchingManualOrders: true
      };
    case actions.GET_MANUAL_ORDERS_SUCCESS:
      return {
        ...state,
        isFetchingManualOrders: false,
        manualOrders: action.payload
      };
    case actions.SAVE_BROKER_ORDER_BEFORE_REQUEST:
      return {
        ...state,
        isProcessingManualOrder: true
      };
    case actions.SAVE_BROKER_ORDER_SUCCESS:
      return {
        ...state,
        isProcessingManualOrder: false
      };
    case actions.SAVE_BROKER_ORDER_CHANGE_STATUS:
      return {
        ...state,
        manualOrders: state.manualOrders.map(order => {
          if (order._id === action.payload._id) return action.payload;
          else return order;
        })
      };
    case actions.DELETE_MANUAL_ORDER_BEFORE_REQUEST:
      return {
        ...state,
        isDeletingManualOrder: true
      };
    case actions.DELETE_MANUAL_ORDER_SUCCESS:
      return {
        ...state,
        isDeletingManualOrder: false
      };
    case actions.DELETE_MANUAL_ORDER_FILTER:
      return {
        ...state,
        manualOrders: state.manualOrders.filter(
          ({ _id }) => _id !== action.payload
        )
      };
    default:
      return state;
  }
};

export default listings;

export const getOrderStatusListings = state => state.orderStatuslistings;
export const getIsCreatingOrder = state => state.isCreatingOrder;
export const getIsFetchingCustomers = state => state.isFetchingCustomers;
export const getCustomerDisplayNames = state => state.customerDisplayNames;
export const getIsFetchingManualOrders = state => state.isFetchingManualOrders;
export const getAllManualOrders = state => state.manualOrders;
export const getIsProcessingManualOrder = state =>
  state.isProcessingManualOrder;
export const getIsDeletingManualOrder = state => state.isDeletingManualOrder;
