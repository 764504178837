import { restApiClient } from ".";

//Order Status Listings
export const fetchOrderStatus = (
  statusType,
  eventName,
  eventId,
  invoiceId,
  eventAddress,
  startDate,
  endDate,
  page,
  limit
) => {
  return restApiClient.get(`/getOrderStatus`, {
    params: {
      status: statusType,
      eventName: eventName,
      eventId: eventId,
      invoiceId: invoiceId,
      eventAddress: eventAddress,
      startDate: startDate,
      endDate: endDate,
      page: page,
      limit: limit
    }
  });
};

export const createTJOrder = orderDetails => {
  return restApiClient.post("/createTJOrder", orderDetails);
};

export const getCustomerDisplayData = ({
  pos_broadcaster = "1.0",
  brokerAccId = ""
}) => {
  return restApiClient.get("/getSkyboxCustomers", {
    params: {
      pos_broadcaster,
      brokerAccId
    }
  });
};

export const getAllManualOrders = () => {
  return restApiClient.get("/getAllManualOrders", {
    params: {
      isAdmin: true,
      brokerAccId: ""
    }
  });
};

export const saveBrokerOrderRequest = orderDetails => {
  return restApiClient.post("/saveBrokerOrderRequest", orderDetails);
};

export const deleteManualOrder = _id => {
  return restApiClient.delete("/deleteManualOrder", {
    params: {
      _id
    }
  });
};
