import { restApiClient } from ".";

export const fetchTicketsByEventId = eventId => {
  if (!eventId) return;

  return restApiClient.get(`/events/${eventId}/getEventListings`);
};

export const resaleMedianTickets = (page, limit, eventFilters) => {
  return restApiClient.post(
    `/getResaleMedianTickets`,
    { filter: eventFilters },
    {
      params: {
        page,
        limit
      }
    }
  );
};

export const archiveMedianTickets = Ids => {
  return restApiClient.post(`/archiveMedianListing`, { Ids });
};
