import { createAction } from "redux-actions";

export const FETCH_SALE_MARGIN_HEALTH_REPORT_REQUEST =
  "FETCH_SALE_MARGIN_HEALTH_REPORT_REQUEST";
export const fetchSaleMarginHealthReportRequest = createAction(
  FETCH_SALE_MARGIN_HEALTH_REPORT_REQUEST
);

export const FETCH_SALE_MARGIN_HEALTH_REPORT_SUCCESS =
  "FETCH_SALE_MARGIN_HEALTH_REPORT_SUCCESS";
export const fetchSaleMarginHealthReportSuccess = createAction(
  FETCH_SALE_MARGIN_HEALTH_REPORT_SUCCESS
);

export const FETCH_MARKETWISE_SALE_MARGIN_HEALTH_REPORT_REQUEST =
  "FETCH_MARKETWISE_SALE_MARGIN_HEALTH_REPORT_REQUEST";
export const fetchMarketwiseSaleMarginHealthReportRequest = createAction(
  FETCH_MARKETWISE_SALE_MARGIN_HEALTH_REPORT_REQUEST
);

export const FETCH_MARKETWISE_SALE_MARGIN_HEALTH_REPORT_SUCCESS =
  "FETCH_MARKETWISE_SALE_MARGIN_HEALTH_REPORT_SUCCESS";
export const fetchMarketwiseSaleMarginHealthReportSuccess = createAction(
  FETCH_MARKETWISE_SALE_MARGIN_HEALTH_REPORT_SUCCESS
);

export const FETCH_SB_EVENTS_HEALTH_REPORT_REQUEST =
  "FETCH_SB_EVENTS_HEALTH_REPORT_REQUEST";
export const fetchSBEventsHealthReportRequest = createAction(
  FETCH_SB_EVENTS_HEALTH_REPORT_REQUEST
);

export const FETCH_SB_EVENTS_HEALTH_REPORT_SUCCESS =
  "FETCH_SB_EVENTS_HEALTH_REPORT_SUCCESS";
export const fetchSBEventsHealthReportSuccess = createAction(
  FETCH_SB_EVENTS_HEALTH_REPORT_SUCCESS
);

export const FETCH_SB_POSTED_EVENTS_HEALTH_REPORT_REQUEST =
  "FETCH_SB_POSTED_EVENTS_HEALTH_REPORT_REQUEST";
export const fetchSBPostedEventsHealthReportRequest = createAction(
  FETCH_SB_POSTED_EVENTS_HEALTH_REPORT_REQUEST
);

export const FETCH_SB_POSTED_EVENTS_HEALTH_REPORT_SUCCESS =
  "FETCH_SB_POSTED_EVENTS_HEALTH_REPORT_SUCCESS";
export const fetchSBPostedEventsHealthReportSuccess = createAction(
  FETCH_SB_POSTED_EVENTS_HEALTH_REPORT_SUCCESS
);

export const FETCH_SB_POSTED_PRESALE_EVENTS_HEALTH_REPORT_REQUEST =
  "FETCH_SB_POSTED_PRESALE_EVENTS_HEALTH_REPORT_REQUEST";
export const fetchSBPostedPresaleEventsHealthReportRequest = createAction(
  FETCH_SB_POSTED_PRESALE_EVENTS_HEALTH_REPORT_REQUEST
);

export const FETCH_SB_POSTED_PRESALE_EVENTS_HEALTH_REPORT_SUCCESS =
  "FETCH_SB_POSTED_PRESALE_EVENTS_HEALTH_REPORT_SUCCESS";
export const fetchSBPostedPresaleEventsHealthReportSuccess = createAction(
  FETCH_SB_POSTED_PRESALE_EVENTS_HEALTH_REPORT_SUCCESS
);

export const FETCH_EVENT_QUEUE_HEALTH_REPORT_REQUEST =
  "FETCH_EVENT_QUEUE_HEALTH_REPORT_REQUEST";
export const fetchEventQueueHealthReportRequest = createAction(
  FETCH_EVENT_QUEUE_HEALTH_REPORT_REQUEST
);

export const FETCH_EVENT_QUEUE_HEALTH_REPORT_SUCCESS =
  "FETCH_EVENT_QUEUE_HEALTH_REPORT_SUCCESS";
export const fetchEventQueueHealthReportSuccess = createAction(
  FETCH_EVENT_QUEUE_HEALTH_REPORT_SUCCESS
);

export const FETCH_CANCELLED_SB_EVENTS_HEALTH_REPORT_REQUEST =
  "FETCH_CANCELLED_SB_EVENTS_HEALTH_REPORT_REQUEST";
export const fetchCancelledSBEventsHealthReportRequest = createAction(
  FETCH_CANCELLED_SB_EVENTS_HEALTH_REPORT_REQUEST
);

export const FETCH_CANCELLED_SB_EVENTS_HEALTH_REPORT_SUCCESS =
  "FETCH_CANCELLED_SB_EVENTS_HEALTH_REPORT_SUCCESS";
export const fetchCancelledSBEventsHealthReportSuccess = createAction(
  FETCH_CANCELLED_SB_EVENTS_HEALTH_REPORT_SUCCESS
);

export const FETCH_UPDATE_HEALTH_REPORT_CONFIG_REQUEST =
  "FETCH_UPDATE_HEALTH_REPORT_CONFIGhr_REQUEST";
export const fetchUpdateHealthReportConfigRequest = createAction(
  FETCH_UPDATE_HEALTH_REPORT_CONFIG_REQUEST
);

export const FETCH_UPDATE_HEALTH_REPORT_CONFIG_SUCCESS =
  "FETCH_UPDATE_HEALTH_REPORT_CONFIG_SUCCESS";
export const fetchUpdateHealthReportConfigSuccess = createAction(
  FETCH_UPDATE_HEALTH_REPORT_CONFIG_SUCCESS
);

export const FETCH_PRESALE_EVENTS_HEALTH_REPORT_REQUEST =
  "FETCH_PRESALE_EVENTS_HEALTH_REPORT_REQUEST";
export const fetchPresaleEventsHealthReportRequest = createAction(
  FETCH_PRESALE_EVENTS_HEALTH_REPORT_REQUEST
);

export const FETCH_PRESALE_EVENTS_HEALTH_REPORT_SUCCESS =
  "FETCH_PRESALE_EVENTS_HEALTH_REPORT_SUCCESS";
export const fetchPresaleEventsHealthReportSuccess = createAction(
  FETCH_PRESALE_EVENTS_HEALTH_REPORT_SUCCESS
);

export const FETCH_EVENT_MONITORING_HEALTH_REPORT_REQUEST =
  "FETCH_EVENT_MONITORING_HEALTH_REPORT_REQUEST";
export const fetchEventMonitoringHealthReportRequest = createAction(
  FETCH_EVENT_MONITORING_HEALTH_REPORT_REQUEST
);

export const FETCH_EVENT_MONITORING_HEALTH_REPORT_SUCCESS =
  "FETCH_EVENT_MONITORING_HEALTH_REPORT_SUCCESS";
export const fetchEventMonitoringHealthReportSuccess = createAction(
  FETCH_EVENT_MONITORING_HEALTH_REPORT_SUCCESS
);

export const FETCH_EVENT_ORDERS_HEALTH_REPORT_REQUEST =
  "FETCH_EVENT_ORDERS_HEALTH_REPORT_REQUEST";
export const fetchEventOrdersHealthReportRequest = createAction(
  FETCH_EVENT_ORDERS_HEALTH_REPORT_REQUEST
);

export const CHECK_MONITORING_STATUS_REQUEST =
  "CHECK_MONITORING_STATUS_REQUEST";
export const checkMoniotringStatusRequest = createAction(
  CHECK_MONITORING_STATUS_REQUEST
);

export const CHECK_MONITORING_STATUS_REQUEST_SUCCESS =
  "CHECK_MONITORING_STATUS_REQUEST_SUCCESS";
export const checkMoniotringStatusRequestSuccess = createAction(
  CHECK_MONITORING_STATUS_REQUEST_SUCCESS
);

export const FETCH_EVENT_ORDERS_HEALTH_REPORT_SUCCESS =
  "FETCH_EVENT_ORDERS_HEALTH_REPORT_SUCCESS";
export const fetchEventOrdersHealthReportSuccess = createAction(
  FETCH_EVENT_ORDERS_HEALTH_REPORT_SUCCESS
);

export const FETCH_REPRICE_HEALTH_REPORT_REQUEST =
  "FETCH_REPRICE_HEALTH_REPORT_REQUEST";
export const fetchRepriceHealthReportRequest = createAction(
  FETCH_REPRICE_HEALTH_REPORT_REQUEST
);

export const FETCH_REPRICE_HEALTH_REPORT_SUCCESS =
  "FETCH_REPRICE_HEALTH_REPORT_SUCCESS";
export const fetchRepriceHealthReportSuccess = createAction(
  FETCH_REPRICE_HEALTH_REPORT_SUCCESS
);

export const FETCH_SB_STATISTICS_HEALTH_REPORT_REQUEST =
  "FETCH_SB_STATISTICS_HEALTH_REPORT_REQUEST";
export const fetchSbStatisticsHealthReportRequest = createAction(
  FETCH_SB_STATISTICS_HEALTH_REPORT_REQUEST
);

export const FETCH_SB_STATISTICS_HEALTH_REPORT_SUCCESS =
  "FETCH_SB_STATISTICS_HEALTH_REPORT_SUCCESS";
export const fetchSbStatisticsHealthReportSuccess = createAction(
  FETCH_SB_STATISTICS_HEALTH_REPORT_SUCCESS
);

export const FETCH_SB_STATISTICS_HEALTH_REPORT_FAILURE =
  "FETCH_SB_STATISTICS_HEALTH_REPORT_FAILURE";
export const fetchSbStatisticsHealthReportFailure = createAction(
  FETCH_SB_STATISTICS_HEALTH_REPORT_FAILURE
);
