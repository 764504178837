import {
  FETCH_EVENTS_LOG_SUCCESS,
  FETCH_CRON_MONITORING_LOG_SUCCESS,
  FETCH_LOG_DETAILS_SUCCESS,
  FETCH_LONG_TERM_LOG_SUCCESS,
  FETCH_MEDIUM_TERM_LOG_SUCCESS,
  FETCH_VIEW_LOG_SUCCESS,
  FETCH_INSTANCE_LOG_SUCCESS,
  FETCH_FAILED_EVENT_LOG_SUCCESS,
  FETCH_EVENT_LOG_INFO_SUCCESS,
  FETCH_TRIGGER_ALERT_FOR_EVENTS_SUCCESS,
  FETCH_ALERT_INFO_SUCCESS,
  FETCH_ALERT_INFO_REQUEST,
  FETCH_ALERT_INFO_STOP_FETCHING,
  MAIN_MARKET_STATS_START_FETCHING,
  MAIN_MARKET_STATS_STOP_FETCHING,
  LOG_VIEW_START_FETCHING,
  LOG_VIEW_STOP_FETCHING,
  BROADWAYDIRECT_STATS_START_FETCHING,
  BROADWAYDIRECT_STATS_STOP_FETCHING,
  NYCBALLET_STATS_START_FETCHING,
  NYCBALLET_STATS_STOP_FETCHING,
  MLB_STATS_START_FETCHING,
  MLB_STATS_STOP_FETCHING
} from "../actions/logs";

const logs = (
  state = {
    eventsLogs: {
      shortTerm: [],
      nearTerm: [],
      mediumTerm: [],
      longTerm: [],
      coldTerm: [],
      shortBroadwayDirectTerm: [],
      nearBroadwayDirectTerm: [],
      mediumBroadwayDirectTerm: [],
      longBroadwayDirectTerm: [],
      coldBroadwayDirectTerm: [],
      shortNYCBalletTerm: [],
      nearNYCBalletTerm: [],
      mediumNYCBalletTerm: [],
      longNYCBalletTerm: [],
      coldNYCBalletTerm: [],
      shortMLBTerm: [],
      nearMLBTerm: [],
      mediumMLBTerm: [],
      longMLBTerm: [],
      coldMLBTerm: [],
      eVenueTerm: []
    },
    cronMonitoringLog: {
      typeWise: [],
      instanceWise: []
    },
    viewLog: {},
    eventDetailLogs: {},
    logDownloaded: null,
    failedEventLogDetails: {},
    eventsLogInfo: {},
    alertTriggerInfo: [],
    alertInfoTypes: [],
    isAlertInfoFetching: false,
    isMainMarketStatsFetching: false,
    isLogViewFetching: false,
    isBroadwayDirectStatsFetching: false,
    isNYCBalletStatsFetching: false,
    isMlbStatsFetching: false
  },
  action
) => {
  switch (action.type) {
    case FETCH_EVENTS_LOG_SUCCESS:
      return {
        ...state,
        eventsLogs: {
          ...state.eventsLogs,
          ...action.payload
        }
      };
    case FETCH_CRON_MONITORING_LOG_SUCCESS:
      return {
        ...state,
        cronMonitoringLog: action.payload
      };
    case FETCH_LOG_DETAILS_SUCCESS:
      return {
        ...state,
        nearLogs: action.payload
      };
    case FETCH_MEDIUM_TERM_LOG_SUCCESS:
      return {
        ...state,
        mediumTermLogs: action.payload
      };
    case FETCH_LONG_TERM_LOG_SUCCESS:
      return {
        ...state,
        longTermLogs: action.payload
      };
    case FETCH_VIEW_LOG_SUCCESS:
      return {
        ...state,
        viewLog: action.payload,
        isLogViewFetching: false
      };
    case FETCH_INSTANCE_LOG_SUCCESS:
      return { ...state, logDownloaded: action.payload };
    case FETCH_FAILED_EVENT_LOG_SUCCESS:
      return { ...state, failedEventLogDetails: action.payload };
    case FETCH_EVENT_LOG_INFO_SUCCESS:
      return { ...state, eventsLogInfo: action.payload };
    case FETCH_TRIGGER_ALERT_FOR_EVENTS_SUCCESS:
      return { ...state, alertTriggerInfo: action.payload };
    case FETCH_ALERT_INFO_SUCCESS:
      return {
        ...state,
        alertInfoTypes: action.payload,
        isAlertInfoFetching: false
      };
    case FETCH_ALERT_INFO_REQUEST:
      return { ...state, isAlertInfoFetching: true };
    case FETCH_ALERT_INFO_STOP_FETCHING:
      return { ...state, isAlertInfoFetching: false };
    case MAIN_MARKET_STATS_START_FETCHING:
      return { ...state, isMainMarketStatsFetching: true };
    case MAIN_MARKET_STATS_STOP_FETCHING:
      return { ...state, isMainMarketStatsFetching: false };
    case LOG_VIEW_START_FETCHING:
      return { ...state, isLogViewFetching: true };
    case LOG_VIEW_STOP_FETCHING:
      return { ...state, isLogViewFetching: false };
    case BROADWAYDIRECT_STATS_START_FETCHING:
      return { ...state, isBroadwayDirectStatsFetching: true };
    case BROADWAYDIRECT_STATS_STOP_FETCHING:
      return { ...state, isBroadwayDirectStatsFetching: false };
    case NYCBALLET_STATS_START_FETCHING:
      return { ...state, isNYCBalletStatsFetching: true };
    case NYCBALLET_STATS_STOP_FETCHING:
      return { ...state, isNYCBalletStatsFetching: false };
    case MLB_STATS_START_FETCHING:
      return { ...state, isMlbStatsFetching: true };
    case MLB_STATS_STOP_FETCHING:
      return { ...state, isMlbStatsFetching: false };
    default:
      return state;
  }
};

export default logs;
export const getEventsLog = state => state.eventsLogs;
export const getCronMonitoringLog = state => state.cronMonitoringLog;
export const getShortTermLogs = state => state.shortTermLogs;
export const getNearLogs = state => state.nearLogs;
export const getMediumTermLogs = state => state.mediumTermLogs;
export const getLongTermLogs = state => state.longTermLogs;
export const getEventLogDetails = state => state.eventDetailLogs;
export const getViewLog = state => state.viewLog;
export const getDownLoadedInstanceLog = state => state.logDownloaded;
export const getFailedEventLog = state => state.failedEventLogDetails;
export const getEventsLogInfo = state => state.eventsLogInfo;
export const getAlertTriggerInfo = state => state.alertTriggerInfo;
export const getAllAlertInfoTypes = state => state.alertInfoTypes;
export const getIsAlertInfoFetching = state => state.isAlertInfoFetching;
export const getIsMainMarketFetching = state => state.isMainMarketStatsFetching;
export const getIsLogViewFetching = state => state.isLogViewFetching;
export const getIsBroadwayDirectStatsFetching = state =>
  state.isBroadwayDirectStatsFetching;
export const getIsNYCBalletStatsFetching = state =>
  state.isNYCBalletStatsFetching;
export const getIsMlbStatsFetching = state => state.isMlbStatsFetching;
