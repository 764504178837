import { takeEvery, call, put } from "redux-saga/effects";
import * as actions from "../actions/postingLogs";
import * as userActions from "../actions/users";
import * as appActions from "../actions/app";
import * as api from "../api/postingLogs";

function* fetchEventsPostingLogSaga(action) {
  try {
    const { data } = yield call(api.fetchEventsPostingLog, action.payload);
    if (data.success) {
      yield put(
        actions.fetchEventsPostingLogSuccess({
          data: data.data,
          ...action.payload
        })
      );
    }
  } catch (error) {
    if (error.response.status === 403)
      yield put(userActions.userAuthorizationFailure(error));
    else yield put(appActions.appReceiveError(error));
  } finally {
    // yield put(actions.mainMarketStatsStopFetching());
  }
}

function* fetchEventsPostingDetailLogSaga(action) {
  try {
    const { data } = yield call(api.fetchEventsPostingLog, action.payload);
    if (data.success) {
      yield put(
        actions.fetchEventPostingDetailLogsSuccess({
          data: data.data,
          ...action.payload
        })
      );
    }
  } catch (error) {
    if (error.response.status === 403)
      yield put(userActions.userAuthorizationFailure(error));
    else yield put(appActions.appReceiveError(error));
  } finally {
    // yield put(actions.mainMarketStatsStopFetching());
  }
}

function* watchLogsSaga() {
  yield takeEvery(
    actions.fetchEventsPostingLogRequest,
    fetchEventsPostingLogSaga
  );

  yield takeEvery(
    actions.fetchEventPostingDetailLogsRequest,
    fetchEventsPostingDetailLogSaga
  );
}

export default watchLogsSaga;
