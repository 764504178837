import {
  FETCH_API_CALL_SUMMARY_DATA_REQUEST,
  FETCH_API_CALL_SUMMARY_DATA_SUCCESS,
  FETCH_TOKEN_SUMMARY_DATA_REQUEST,
  FETCH_TOKEN_SUMMARY_DATA_SUCCESS,
  FETCH_TOKEN_TYPE_DATA_REQUEST,
  FETCH_TOKEN_TYPE_DATA_SUCCESS,
  FETCH_TOKEN_SUMMARY_REPORT_REQUEST,
  FETCH_TOKEN_SUMMARY_REPORT_SUCCESS,
  FETCH_TOKEN_TYPE_REPORT_REQUEST,
  FETCH_TOKEN_TYPE_REPORT_SUCCESS,
  FETCH_API_CALL_SUMMARY_REPORT_REQUEST,
  FETCH_API_CALL_SUMMARY_REPORT_SUCCESS
} from "../actions/apiTokenStatistics"

const initialState = {
  tokenSummary: {},
  isFetchingApiTokenSummary: false,
  tokenTypeData: {},
  isFetchingTokenTypeData: false,
  apiCallSummaryData: {},
  isFetchingApiCallSummaryData: false,
  tokenSummaryReport: {},
  isFetchingApiTokenSummaryReport: false,
  tokenTypeReport: {},
  isFetchingTokenTypeReport: false,
  apiCallSummaryReport: {},
  isFetchingApiCallSummaryReport: false
}
const apiTokenStatistics = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TOKEN_SUMMARY_DATA_REQUEST:
      return {
        ...state,
        isFetchingApiTokenSummary: true
      }
    case FETCH_TOKEN_SUMMARY_DATA_SUCCESS:
      let data = action.payload.data
      return {
        ...state,
        isFetchingApiTokenSummary: false,
        tokenSummary: {
          ...data,
          page: action.payload.page
        }
      }
    case FETCH_TOKEN_TYPE_DATA_REQUEST:
      return {
        ...state,
        isFetchingTokenTypeData: true
      }
    case FETCH_TOKEN_TYPE_DATA_SUCCESS:
      let tokenData = action.payload.data
      return {
        ...state,
        isFetchingTokenTypeData: false,
        tokenTypeData: {
          ...tokenData,
          page: action.payload.page
        }
      }
    case FETCH_API_CALL_SUMMARY_DATA_REQUEST:
      return {
        ...state,
        isFetchingApiCallSummaryData: true
      }
    case FETCH_API_CALL_SUMMARY_DATA_SUCCESS:
      let apiCallData = action.payload.data
      return {
        ...state,
        isFetchingApiCallSummaryData: false,
        apiCallSummaryData: {
          ...apiCallData,
          page: action.payload.page
        }
      }
    case FETCH_TOKEN_SUMMARY_REPORT_REQUEST:
      return {
        ...state,
        isFetchingApiTokenSummaryReport: true
      }
    case FETCH_TOKEN_SUMMARY_REPORT_SUCCESS:
      let summaryReport = action.payload.data
      return {
        ...state,
        isFetchingApiTokenSummaryReport: false,
        tokenSummaryReport: {
          ...summaryReport,
          page: action.payload.page
        }
      }
    case FETCH_TOKEN_TYPE_REPORT_REQUEST:
      return {
        ...state,
        isFetchingTokenTypeReport: true
      }
    case FETCH_TOKEN_TYPE_REPORT_SUCCESS:
      let tokenReport = action.payload.data
      return {
        ...state,
        isFetchingTokenTypeReport: false,
        tokenTypeReport: {
          ...tokenReport,
          page: action.payload.page
        }
      }
    case FETCH_API_CALL_SUMMARY_REPORT_REQUEST:
      return {
        ...state,
        isFetchingApiCallSummaryReport: true
      }
    case FETCH_API_CALL_SUMMARY_REPORT_SUCCESS:
      let apiCallReport = action.payload.data
      return {
        ...state,
        isFetchingApiCallSummaryReport: false,
        apiCallSummaryReport: {
          ...apiCallReport,
          page: action.payload.page
        }
      }
    default:
      return state
  }
}

export default apiTokenStatistics

export const getTokenSummaryData = state => state.tokenSummary
export const getIsFetchingApiTokenSummary = state =>
  state.isFetchingApiTokenSummary

export const getTokenTypeData = state => state.tokenTypeData
export const getIsFetchingTokenTypeData = state => state.isFetchingTokenTypeData

export const getApiCallSummaryData = state => state.apiCallSummaryData
export const getIsFetchingApiCallSummaryData = state =>
  state.isFetchingApiCallSummaryData

// report data
export const getTokenSummaryReport = state => state.tokenSummaryReport
export const getIsFetchingApiTokenSummaryReport = state =>
  state.isFetchingApiTokenSummaryReport

export const getTokenTypeReport = state => state.tokenTypeReport
export const getIsFetchingTokenTypeReport = state =>
  state.isFetchingTokenTypeReport

export const getApiCallSummaryReport = state => state.apiCallSummaryReport
export const getIsFetchingApiCallSummaryReport = state =>
  state.isFetchingApiCallSummaryReport
