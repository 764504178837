import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

import * as appActions from "../actions/app";
import * as actions from "../actions/tickets";
import * as api from "../api/tickets";
import * as constants from "../constants";
import { dateFormatterWithTZ } from "../utils";

function* fetchTicketsByEventIdSaga(action) {
  const { eventId } = action.payload;

  try {
    yield put(appActions.appStartFetching());
    const { data } = yield call(api.fetchTicketsByEventId, eventId);
    Object.keys(data).length //data might be a empty object due to tickets are sold out
      ? yield put(actions.fetchTicketsByEventIdSuccess(data))
      : yield put(
          appActions.appReceiveError({
            message: `Event not found or Tickets already sold out! Please Try another event!`,
            type: constants.ERROR_EVENT_NOT_FOUND
          })
        );
  } catch (error) {
    yield put(
      appActions.appReceiveError({
        message: `Event not found or Tickets already sold out! Please Try another event!`,
        type: constants.ERROR_EVENT_NOT_FOUND
      })
    );
  } finally {
    yield put(appActions.appStopFetching());
  }
}

function formateResaleMedianData(tickets) {
  return tickets.map(ticket => {
    const {
      section,
      row,
      quantity,
      pricePoint,
      eventId,
      eventData,
      medianCost,
      baseCost,
      blueSeatPrice,
      pctVenueAvail,
      pctVenueRedAvail,
      blueSeatsAvail,
      belowMedianPercentage,
      _id
    } = ticket;

    return {
      eventAddress: eventData.eventAddress,
      eventId,
      eventName: eventData.eventName,
      eventDate: eventData.eventDate,
      formattedEventDate: dateFormatterWithTZ(eventData.eventDate)(
        eventData.timeZone === undefined
          ? "America/New_York"
          : eventData.timeZone
      ),
      skyBoxEventId: eventData.skyBoxEventId,
      medianCost: medianCost.toFixed(2),
      baseCost,
      blueSeatPrice,
      venueId: eventData.venueId,
      belowMedianPercentage: belowMedianPercentage.toFixed(2) + " %",
      blueSeatsAvail: blueSeatsAvail.toFixed(2) + " %",
      secRow: `${section}, ${row}`,
      pctVenueAvail: pctVenueAvail
        ? (pctVenueAvail * 100).toFixed(2) + " %"
        : null,
      pctVenueRedAvail: pctVenueRedAvail
        ? (pctVenueRedAvail * 100).toFixed(2) + " %"
        : null,
      quantity,
      pricePoint,
      _id
    };
  });
}

function* resaleMedianTicketsSaga(action) {
  try {
    yield put(appActions.appStartFetching());
    const { data } = yield call(
      api.resaleMedianTickets,
      action.payload.page,
      action.payload.limit,
      action.payload.filter
    );

    yield put(
      actions.fetchResaleMedianTicketsSuccess({
        medianListings: formateResaleMedianData(data.resaleMedianData),
        totalListing: data.totalEventTickets,
        page: action.payload.page
      })
    );

    yield put(appActions.appStopFetching());
  } catch (error) {
    yield put(appActions.appStopFetching());
  }
}

function* resaleMedianTicketsCountSaga(action) {
  try {
    const { data } = yield call(
      api.resaleMedianTickets,
      action.payload.page,
      action.payload.limit,
      action.payload.filter
    );

    yield put(
      actions.fetchResaleMedianTicketsCountSuccess(data.totalEventTickets)
    );
  } catch (error) {}
}

function* archiveMedianListingSaga(action) {
  try {
    // yield put(appActions.appStartFetching());
    const { data } = yield call(api.archiveMedianTickets, action.payload.Ids);

    if (data && data.success) {
      yield put(
        appActions.appReceiveAlert({
          message: "Succeed updating Account!"
        })
      );
    }
    // yield put(appActions.appStopFetching());
  } catch (error) {
    yield put(appActions.appStopFetching());
  }
}

function* watchTicketsSaga() {
  // while (true) {
  // const action = yield take(actions.FFETCH_TICKETS_BY_EVENTID_REQUEST)

  // const task = yield fork(fetchTicketsByEventIdSaga, action)

  // yield take(actions.FFETCH_TICKETS_BY_EVENTID_STOP_REQUEST)

  yield takeEvery(
    actions.fetchTicketsByEventIdRequest,
    fetchTicketsByEventIdSaga
  );

  yield takeEvery(
    actions.fetchResaleMedianTicketsRequest,
    resaleMedianTicketsSaga
  );

  yield takeLatest(
    actions.fetchResaleMedianTicketsCountRequest,
    resaleMedianTicketsCountSaga
  );

  yield takeEvery(
    actions.archiveMedianListingRequest,
    archiveMedianListingSaga
  );

  // yield cancel(task)
  // }
}

export default watchTicketsSaga;
