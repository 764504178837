import { takeEvery, call, put } from "redux-saga/effects";
import * as appActions from "../actions/app";
import * as userActions from "../actions/users";
import * as actions from "../actions/apiTokenStatistics";
import * as api from "../api/apiTokenStatistics";

function* fetchTokenSummaryDataSaga(action) {
  try {
    yield put(appActions.appStartFetching());
    const { data } = yield call(api.fetchTokenSummaryData, action.payload);
    yield put(
      actions.fetchTokenSummaryDataSuccess({
        data: data.data,
        page: action.payload.page
      })
    );
  } catch (error) {
    if (error.response.status === 403)
      yield put(userActions.userAuthorizationFailure(error));
    else yield put(appActions.appReceiveError(error));
  } finally {
    yield put(appActions.appStopFetching());
  }
}

function* fetchTokenTypeDataSaga(action) {
  try {
    yield put(appActions.appStartFetching());
    const { data } = yield call(api.fetchTokenTypeData, action.payload);
    yield put(
      actions.fetchTokenTypeDataSuccess({
        data: data.data,
        page: action.payload.page
      })
    );
  } catch (error) {
    if (error.response.status === 403)
      yield put(userActions.userAuthorizationFailure(error));
    else yield put(appActions.appReceiveError(error));
  } finally {
    yield put(appActions.appStopFetching());
  }
}

function* fetchApiCallSummaryDataSaga(action) {
  try {
    yield put(appActions.appStartFetching());
    const { data } = yield call(api.fetchApiCallSummaryData, action.payload);
    yield put(
      actions.fetchApiCallSummaryDataSuccess({
        data: data.data,
        page: action.payload.page
      })
    );
  } catch (error) {
    if (error.response.status === 403)
      yield put(userActions.userAuthorizationFailure(error));
    else yield put(appActions.appReceiveError(error));
  } finally {
    yield put(appActions.appStopFetching());
  }
}

function* fetchTokenSummaryReportSaga(action) {
  try {
    yield put(appActions.appStartFetching());
    const { data } = yield call(api.fetchTokenSummaryReport, action.payload);
    yield put(
      actions.fetchTokenSummaryReportSuccess({
        data: data.data,
        page: action.payload.page
      })
    );
  } catch (error) {
    if (error.response.status === 403)
      yield put(userActions.userAuthorizationFailure(error));
    else yield put(appActions.appReceiveError(error));
  } finally {
    yield put(appActions.appStopFetching());
  }
}

function* fetchTokenTypeReportSaga(action) {
  try {
    yield put(appActions.appStartFetching());
    const { data } = yield call(api.fetchTokenTypeReport, action.payload);
    yield put(
      actions.fetchTokenTypeReportSuccess({
        data: data.data,
        page: action.payload.page
      })
    );
  } catch (error) {
    if (error.response.status === 403)
      yield put(userActions.userAuthorizationFailure(error));
    else yield put(appActions.appReceiveError(error));
  } finally {
    yield put(appActions.appStopFetching());
  }
}

function* fetchApiCallSummaryReportSaga(action) {
  try {
    yield put(appActions.appStartFetching());
    const { data } = yield call(api.fetchApiCallSummaryReport, action.payload);
    yield put(
      actions.fetchApiCallSummaryReportSuccess({
        data: data.data,
        page: action.payload.page
      })
    );
  } catch (error) {
    if (error.response.status === 403)
      yield put(userActions.userAuthorizationFailure(error));
    else yield put(appActions.appReceiveError(error));
  } finally {
    yield put(appActions.appStopFetching());
  }
}

function* watchApiTokenStatisticsSaga() {
  yield takeEvery(
    actions.FETCH_TOKEN_SUMMARY_DATA_REQUEST,
    fetchTokenSummaryDataSaga
  );

  yield takeEvery(
    actions.FETCH_TOKEN_TYPE_DATA_REQUEST,
    fetchTokenTypeDataSaga
  );

  yield takeEvery(
    actions.FETCH_API_CALL_SUMMARY_DATA_REQUEST,
    fetchApiCallSummaryDataSaga
  );

  yield takeEvery(
    actions.FETCH_TOKEN_SUMMARY_REPORT_REQUEST,
    fetchTokenSummaryReportSaga
  );

  yield takeEvery(
    actions.FETCH_TOKEN_TYPE_REPORT_REQUEST,
    fetchTokenTypeReportSaga
  );

  yield takeEvery(
    actions.FETCH_API_CALL_SUMMARY_REPORT_REQUEST,
    fetchApiCallSummaryReportSaga
  );
}

export default watchApiTokenStatisticsSaga;
